<template>
  <div>
    <b-card :header="caption">
      <filter-form ref="filter" @clearTable="onClearTable" />

      <b-table
        v-if="dataList.length > 0"
        :dark="false"
        :fields="fields"
        :hover="true"
        :striped="true"
        :bordered="true"
        :small="false"
        :fixed="true"
        responsive="sm"
        show-empty
        :items="applyShortFilter(dataList)"
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'selected' ? '42px' : 'auto' }"
          />
        </template>

        <template #cell(selected)="data">
          <b-form-checkbox
            :value="data.item.id"
            v-model="selectedItems"
            @change="checkItems($event, data.item)"
          ></b-form-checkbox>
        </template>

        <template #head(selected)="data">
          <b-form-checkbox
            v-model="allItemsSelected"
            :indeterminate="indeterminate"
            @change="selectedAllItems()"
          ></b-form-checkbox>
        </template>

        <template v-slot:cell(id)="data">
          <router-link
            :to="{ path: `${itemEndpoint}/${data.item.id}` }"
            target="_blank"
          >
            {{ data.item.name }}
          </router-link>
        </template>

        <template #empty="scope">
          <div class="h4 text-center">Контрагентів не знайдено</div>
        </template>
      </b-table>

      <b-row v-if="dataList.length > 0">
        <b-col cols="auto" class="mr-auto p-3">
          <nav>
            <b-pagination
              :total-rows="totalItemsCount"
              :per-page="count"
              v-model="page"
              prev-text="Попередня"
              next-text="Наступна"
              hide-goto-end-buttons
            />
          </nav>
        </b-col>
      </b-row>

      <b-row v-if="dataList.length > 0">
        <b-col>
          <b-button variant="primary" size="sm" @click="openModal" class="mb-2">
            <i class="fa fa-dot-circle-o"></i> Створити завдання
          </b-button>
        </b-col>
      </b-row>

      <b-modal
        ref="create-task-modal"
        no-close-on-backdrop
        size="lg"
        hide-footer
      >
        <p class="my-4">
          {{ generationModalText }}
        </p>
        <b-button type="submit" size="sm" variant="success" @click="createTask">
          <i class="fa fa-dot-circle-o"></i> Створити завдання
        </b-button>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "debounce";
import FilterForm from "./forms/FilterForm";
import { mapFields } from "vuex-map-fields";
import filterList from "@/entity/Tasks/CounterpartActions/structure/filter";
import { PERMISSION_VIEW_TASK } from "@/constants";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$auth.can(PERMISSION_VIEW_TASK)) next();
      else next({ name: "Page404" });
    });
  },
  name: "counterpart-list",
  components: { FilterForm },
  data() {
    return {
      filter: filterList,
      fields: [
        { key: "selected", label: "Дії" },
        { key: "id", label: "Контрагент" },
        { key: "edrpou", label: "ЄДРПОУ/РНОКПП" }
      ],
      itemEndpoint: "/entity/counterpart",
      caption: "Дії над контрагентами",
      indeterminate: false,
      allItemsSelected: false,
      selectedItems: [],
      excludeItems: [],
      includeItems: []
    };
  },
  computed: {
    ...mapGetters({
      dataList: "tasks/getAllCounterparts",
      totalItemsCount: "tasks/totalCounterpartItems"
    }),
    ...mapFields("tasks", {
      count: "countCounterparts",
      page: "pageCounterparts"
    }),
    generationModalText() {
      let str = `Буде запущена дія - ${this.filter.taskType.value.name}, `;

      if (this.allItemsSelected && !this.indeterminate) {
        str += `по всім контрагентам. Кількість ${this.totalItemsCount}`;
      } else if (!this.allItemsSelected && this.indeterminate) {
        str += `по контрагентам: ${this.includeItems.toString()}. Кількість
          ${this.filter.includeIds.value.length}`;
      } else if (this.allItemsSelected && this.indeterminate) {
        str += `по всім контрагентам за виключенням ${this.excludeItems.toString()}. Кількість
          ${this.totalItemsCount - this.filter.excludeIds.value.length}`;
      }
      return str;
    }
  },
  created() {
    this.$store.dispatch("tasks/clearCounterparts");
    this.debouncedPaginator = debounce(this.paginator, 500);
  },
  methods: {
    openModal: function() {
      if (!this.allItemsSelected && !this.indeterminate) {
        this.$snotify.error("Оберіть контрагентів");
        return;
      }

      this.$refs["create-task-modal"].show();
    },
    createTask() {
      this.$store.commit("tasks/setCounterpartsFilter", this.filter);
      this.$store
        .dispatch("tasks/createTask", this.filter.taskType.value.id)
        .then(() => {
          this.$router.push({ name: "TasksList" });
        });
    },
    paginator: function() {
      this.$store.dispatch("tasks/setCounterparts").then(() => {
        if (this.allItemsSelected) {
          this.dataList.forEach(value => {
            if (!this.selectedItems.includes(value.id)) {
              this.selectedItems.push(value.id);
            }
          });
        }
      });
    },
    applyShortFilter: data => {
      return data.map(item => {
        return {
          id: item.id,
          edrpou: item.usreou_code
            ? item.usreou_code
            : item.itn_code
            ? item.itn_code
            : item.non_resident_code,
          name: item.name
        };
      });
    },
    onClearTable() {
      this.allItemsSelected = false;
      this.indeterminate = false;
      this.selectedItems = [];
      this.includeItems = [];
      this.excludeItems = [];
    },
    selectedAllItems: function() {
      if (this.allItemsSelected && !this.indeterminate) {
        this.dataList.forEach(value => {
          if (!this.selectedItems.includes(value.id)) {
            this.selectedItems.push(value.id);
          }
        });
      } else {
        this.selectedItems = [];
        this.allItemsSelected = false;
        this.indeterminate = false;
        this.filter.includeIds.value = [];
        this.filter.excludeIds.value = [];
        this.includeItems = [];
        this.excludeItems = [];
      }
    },
    checkItems(items, item) {
      if (
        items.length > 0 &&
        items.length !== this.totalItemsCount &&
        !this.allItemsSelected
      ) {
        // точково обраних
        this.allItemsSelected = false;
        this.indeterminate = true;
        this.filter.includeIds.value = Object.assign(items);
        this.filter.excludeIds.value = [];
        this.excludeItems = [];

        if (
          this.selectedItems.includes(item.id) &&
          this.filter.includeIds.value.includes(item.id)
        ) {
          this.includeItems.push(item.name);
        } else {
          this.includeItems = this.includeItems.filter(i => i !== item.name);
        }
      } else if (items.length === this.totalItemsCount) {
        // обрані всі
        this.allItemsSelected = true;
        this.indeterminate = false;
        this.filter.includeIds.value = [];
        this.filter.excludeIds.value = [];
        this.includeItems = [];
        this.excludeItems = [];
      } else if (
        (this.allItemsSelected && !this.indeterminate) ||
        (this.allItemsSelected && this.indeterminate && items.length > 0)
      ) {
        // точково видалені
        this.allItemsSelected = true;
        this.indeterminate = true;
        this.filter.includeIds.value = [];
        this.includeItems = [];
        if (
          !this.selectedItems.includes(item.id) &&
          !this.filter.excludeIds.value.includes(item.id)
        ) {
          this.filter.excludeIds.value.push(item.id);
          this.excludeItems.push(item.name);
        } else if (this.selectedItems.includes(item.id)) {
          this.filter.excludeIds.value = this.filter.excludeIds.value.filter(
            i => i !== item.id
          );

          this.excludeItems = this.excludeItems.filter(i => i !== item.name);
        }
      } else {
        this.allItemsSelected = false;
        this.indeterminate = false;
        this.filter.includeIds.value = [];
        this.filter.excludeIds.value = [];
        this.includeItems = [];
        this.excludeItems = [];
      }
    }
  },
  watch: {
    page: function() {
      this.debouncedPaginator();
    }
  }
};
</script>
