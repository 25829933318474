export default {
  taskType: {
    name: "taskType",
    type: "where",
    value: ""
  },
  counterpartType: {
    name: "type",
    type: "whereIn",
    value: []
  },
  identificationStatus: {
    name: "identificationStatus",
    type: "whereIn",
    value: []
  },
  currentCrimeRiskLevel: {
    name: "currentCrimeRiskLevel",
    type: "whereIn",
    value: []
  },
  includeIds: {
    name: "id",
    type: "whereIn",
    value: []
  },
  excludeIds: {
    name: "id",
    type: "whereNotIn",
    value: []
  },
  isPublic: {
    name: "isPublic",
    type: "where",
    value: ""
  },
  financialModel: {
    name: "financialModel",
    type: "where",
    value: ""
  }
};
